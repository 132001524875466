import React from "react";
import "../styles/pagenotfound.css";
const PageNotFound = () => {
  return (
    <div className="page-not-found">
      <h4>Page Not Found</h4>
    </div>
  );
};

export default PageNotFound;

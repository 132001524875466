import React from "react";
import KetanMusic from "./pages/KetanMusic";
import "./global.css";
import { Routes, Route, Link } from "react-router-dom";
import PageNotFound from "./components/PageNotFound";

const App = () => {
  return (
    <div>
      {/* <KetanMusic /> */}
      <Routes>
        <Route path="/" element={<KetanMusic />}></Route>
        <Route path="/*" element={<PageNotFound />}></Route>
      </Routes>
    </div>
  );
};

export default App;

import React, { useEffect, useState } from "react";

import { Container, Grid } from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

import parse from "html-react-parser";
import { Link } from "react-router-dom";
import "../../styles/banner.css";
import { homeApi } from "../../ketanapi/Api";

import Loading from "../Loading";

const Banner = () => {
  const [bannerTitle, setBannerTitle] = useState([]);
  const [bannerContent, setBannerContent] = useState([]);
  const [loadingSpaninner, setLoadingSpainner] = useState(true);

  useEffect(() => {
    const fetchHomeApi = async () => {
      try {
        const updateHomeApi = await homeApi();
        setBannerTitle(updateHomeApi);
        setBannerContent(updateHomeApi);
        setLoadingSpainner(false);
      } catch (err) {
        console.log("error", err);
        setLoadingSpainner(false);
      }
    };
    fetchHomeApi();
  }, []);

  return (
    <div>
      <Container>
        <div className="banner-container">
          <div className="image-bannere">
            <img src="/image/KetanIMG.png" alt="" />
          </div>
        </div>

        <div className="about-ketan">
          {loadingSpaninner ? (
            <Loading />
          ) : (
            <Grid container spacing={8}>
              <Grid item lg={6}>
                <div>
                  <h1>
                    {bannerTitle &&
                      bannerTitle[0] &&
                      bannerTitle[0].title &&
                      parse(`${bannerTitle[0].title}`)}
                  </h1>
                </div>

                <Link to="https://www.youtube.com/@KetanChhetri">
                  <button className="visit-button">
                    Visit YouTube Channel
                    <ArrowOutwardIcon />
                  </button>
                </Link>
              </Grid>

              <Grid item lg={6} sx={{ textAlign: "left" }}>
                <span>
                  {bannerContent && bannerContent[0] && bannerContent[0].content
                    ? parse(`${bannerContent[0].content}`)
                    : null}
                </span>
              </Grid>
            </Grid>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Banner;

let api_token = null;

export function getAuthorizations() {
    
    if (!api_token) {
        api_token = process.env.REACT_APP_JWT_TOKEN;
    }

    if (!api_token) {
        console.log("No JWT token found");
        return null;
    }

    const headers = {
      Authorization: `Bearer ${api_token}`,
    };
    return headers
}
import React from "react";
import "../styles/loading.css";
import { ColorRing } from "react-loader-spinner";

const Loading = () => {
  return (
    <div className="Loader-container">
      <ColorRing
        visible={true}
        height="50"
        width="50"
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
        colors={[
          "lightgray",
          "lightgray",
          "lightgray",
          "lightgray",
          "lightgray",
        ]}
        className="banner-loader"
      />
    </div>
  );
};

export default Loading;

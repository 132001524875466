import React, { createContext, useEffect, useState } from "react";
import { SettingApi } from "../ketanapi/Api";

export const ContextApi = createContext();

const ApiProvider = ({ children }) => {
  const [updatedSetting, setUpdateSetting] = useState([]);

  useEffect(() => {
    const fetchSettingApi = async () => {
      try {
        const updateSetting = await SettingApi();
        console.log("updateSetting:", updateSetting);
        setUpdateSetting(updateSetting);
      } catch (err) {
        console.log("error::", err);
      }
    };
    fetchSettingApi();
  }, []);

  return (
    <div>
      <ContextApi.Provider value={{ updatedSetting: updatedSetting }}>
        {children}
      </ContextApi.Provider>
    </div>
  );
};
export default ApiProvider;

import React from "react";
import Header from "../components/header/Header";
import "../styles/page.css";
import Banner from "../components/banner/Banner";
import VideoSlider from "../components/videos/VideoSlider";
import Award from "../components/award/Award";
import Photos from "../components/photos/Photos";
import Footer from "../components/footer/Footer";

const KetanMusic = () => {
  return (
    <>
      <Header />
      <Banner />
      <VideoSlider />
      <Award />
      <Photos />
      <Footer />
    </>
  );
};

export default KetanMusic;

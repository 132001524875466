import { Container } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";

import { BsInstagram } from "react-icons/bs";
import { FaSpotify } from "react-icons/fa";
import { PiSoundcloudLogoFill } from "react-icons/pi";

import React, { useContext, useEffect, useState } from "react";
import "../../styles/header.css";

import { Link } from "react-router-dom";
import { SettingApi } from "../../ketanapi/Api";
import { ContextApi } from "../ContextApi";

const Header = () => {
  //const { updateSetting } = useContext(ContextApi);
  const [updatedSetting, setUpdateSetting] = useState([]);

  useEffect(() => {
    const fetchSettingApi = async () => {
      try {
        const updateSetting = await SettingApi();

        const updateIcons = updateSetting?.filter(
          (item) =>
            item.slug === "instagram" ||
            item.slug === "facebook" ||
            item.slug === "youtube" ||
            item.slug === "spotify" ||
            item.slug === "soundcloud"
        );
        setUpdateSetting(updateIcons);
        console.log("hellooo,", updateIcons);
      } catch (err) {
        console.log("error::", err);
      }
    };
    fetchSettingApi();
  }, []);

  return (
    <Container>
      <div className="image-container">
        <div className="image">
          <img src="../../image/ketan-music.png" alt="" />
        </div>

        <div className="icons-image">
          {updatedSetting?.map((item, index) => (
            <React.Fragment key={index}>
              <Link to={item.value} target="_blank">
                {item.slug === "facebook" && (
                  <FacebookOutlinedIcon style={{ fontSize: "24px" }} />
                )}

                {item.slug === "instagram" && (
                  <BsInstagram style={{ fontSize: "19px" }} />
                )}

                {item.slug === "spotify" && (
                  <FaSpotify style={{ fontSize: "20px" }} />
                )}

                {item.slug === "youtube" && (
                  <YouTubeIcon style={{ fontSize: "27px" }} />
                )}

                {item.slug === "soundcloud" && (
                  <PiSoundcloudLogoFill style={{ fontSize: "25px" }} />
                )}
              </Link>
            </React.Fragment>
          ))}

          <MenuIcon style={{ fontSize: "25px", cursor: "pointer" }} />
        </div>
      </div>
    </Container>
  );
};

export default Header;

import React, { useEffect, useState } from "react";

import Slider from "react-slick";
import { Container } from "@mui/material";

import parse from "html-react-parser";
import { AwardNomineeApi } from "../../ketanapi/Api";

import "../../styles/award.css";

const Award = () => {
  const [awardKetan, setAwardKetan] = useState([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 2,
    centermargin: 10,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchAwardNominee = async () => {
      try {
        const updateAwardNominee = await AwardNomineeApi();
        setAwardKetan(updateAwardNominee);
      } catch (err) {
        console.log("error:", err);
      }
    };
    fetchAwardNominee();
  }, []);

  return (
    <div className="award-container">
      <div className="award-image-container">
        {/* <div>
          <img src="image/ketanspotify.png" alt="" />
        </div> */}
        <div className="spotify">
          <iframe
            src="https://open.spotify.com/embed/artist/6kk5t5teHlpb4sGxhexQp0?utm_source=generator"
            width="100%"
            height="100%"
            frameBorder="0"
            allowFullScreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>
        </div>
      </div>

      <div className="award-header">
        <h5>Awards & Nominations</h5>
      </div>

      <div className="award-section">
        <Container>
          <Slider {...settings}>
            {awardKetan?.map((item, index) => (
              <div className="item" key={index}>
                <span className="item">{parse(item.content)}</span>
              </div>
            ))}
          </Slider>
        </Container>
      </div>
    </div>
  );
};

export default Award;

import { useLocation } from "react-router-dom";

import { Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";

import { GalleryApi, LearnMoreImageApi } from "../../ketanapi/Api";
import "../../styles/photo.css";
import Loading from "../Loading";

let nextPage = "1";
const Photos = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [photoUrl, setPhotoUrl] = useState([]);

  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [loadingSpaninner, setLoadingSpainner] = useState(true);

  useEffect(() => {
    const fetchGalleryApi = async () => {
      try {
        const updateGallery = await GalleryApi();
        setPhotoUrl(updateGallery);
        setLoadingSpainner(false);
      } catch (err) {
        console.log("error:", err);
        setLoadingSpainner(false);
      }
    };
    fetchGalleryApi();
  }, []);

  const handleLearnMore = async () => {
    if (!hasMoreItems) {
      return;
    }
    nextPage++;
    try {
      const updateMoreImageResponse = await LearnMoreImageApi(nextPage);
      console.log("updateMoreImageResponse", updateMoreImageResponse);

      if (updateMoreImageResponse.images.length === 0) {
        setHasMoreItems(false);
      } else {
        const newImages = updateMoreImageResponse.images || [];
        setPhotoUrl((prevPhotoUrl) => [...prevPhotoUrl, ...newImages]);

        queryParams.set("nextPage", nextPage);
      }
    } catch (error) {
      console.error("Error fetching more images:", error);
    }
  };

  return (
    <div>
      <div className="photos-container">
        <Container>
          <div className="photo-topic">
            <h5>Photos</h5>
          </div>
          {loadingSpaninner ? (
            <Loading />
          ) : (
            <Grid container spacing={2}>
              {photoUrl?.map((path, index) => {
                if (index === 5) {
                  return (
                    <Grid key={index} item md={4} className="item">
                      <div className="ketan-photos">
                        <img src={path?.image} alt={`ketan ${index + 1}`} />
                      </div>
                    </Grid>
                  );
                } else if (index === 3) {
                  return (
                    <Grid key={index} item md={12} className="item">
                      <div className="ketan-photos">
                        <img src={path?.image} alt={`ketan ${index + 1}`} />
                      </div>
                    </Grid>
                  );
                } else {
                  return (
                    <Grid
                      key={index}
                      item
                      md={index === 5 ? 4 : 4}
                      className="item"
                    >
                      <div className="ketan-photos">
                        <img src={path?.image} alt={`ketan ${index + 1}`} />
                      </div>
                    </Grid>
                  );
                }
              })}
            </Grid>
          )}
          <div className="learn-button">
            {hasMoreItems ? (
              <button onClick={handleLearnMore}>Load More</button>
            ) : (
              // <p>No more images to load.</p>
              <button>No more Images</button>
            )}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Photos;

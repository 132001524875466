import axios from "axios";
import { getAuthorizations } from "./ApiToken";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// const getToken = () => {
//     return localStorage.getItem("api_token");

// }

// const storeToken = (Token) => {
//    localStorage.setItem("api_token",Token)
// }
// axiosInstance.interceptors.request.use((config) => {
//     const api_token = process.env.REACT_API_TOKEN;
//     console.log("Token of keta home", api_token);
//     if (api_token) {
//         config.headers["Authorization"] = `Bearer ${api_token}`;
//     }
// return config
// },
//     (error) =>Promise.reject(error)
// );

// export {storeToken,getToken};
// const api_token = process.env.REACT_APP_JWT_TOKEN;
// if (!api_token) {
//   console.error("token_api");
//   return null
// }

// const headers = {
//   Authorization: `Bearer ${api_token}`,
// };

export const homeApi = async () => {
  try {
    const headers = getAuthorizations();
    if (!headers) {
      return null;
    }
    const response = await axiosInstance.get("home", { headers });
    console.log("response:", response);
    return response.data.home;
  } catch (err) {
    console.log(err);
  }
};

export const AwardNomineeApi = async () => {
  try {
    const headers = getAuthorizations();
    if (!headers) {
      return null;
    }
    const response = await axiosInstance.get("award", { headers });
    console.log("responses:", response);
    return response.data.awards;
  } catch (err) {
    console.log("error", err);
  }
};

export const GalleryApi = async () => {
  try {
    const headers = getAuthorizations();
    if (!headers) {
      return null;
    }
    const response = await axiosInstance.get("gallery", {
      headers,
    });
    console.log("galleryresponse:", response);
    return response.data.images;
  } catch (err) {
    console.log("error:", err);
  }
};

export const VideoApi = async () => {
  try {
    const headers = getAuthorizations();
    if (!headers) {
      return null;
    }
    const response = await axiosInstance.get("video", { headers });
    console.log("videoresponse:", response);
    return response.data.videos;
  } catch (err) {
    console.log("video error", err);
  }
};

export const SettingApi = async () => {
  try {
    const headers = getAuthorizations();
    if (!headers) {
      return null;
    }
    const response = await axiosInstance.get("setting", { headers });
    console.log("response setting:", response);
    return response.data.setting;
  } catch (err) {
    console.log("error setting:", err);
  }
};

// export const GalleryApi = async () => {
//   try {
//     const headers = getAuthorizations();
//     if (!headers) {
//       return null;
//     }
//     const response = await axiosInstance.get("gallery", {
//       headers,
//     });
//     console.log("galleryresponse:", response);
//     return { images: response.data.images, nextPage: response.data.nextPage };
//   } catch (err) {
//     console.log("error:", err);
//   }
// };
export const LearnMoreImageApi = async (nextPage) => {
  try {
    const headers = getAuthorizations();
    if (!headers) {
      return null;
    }
    const response = await axiosInstance.get(`gallery?page=${nextPage}`, {
      headers,
    });

    if (response.data && response.data.images) {
      return response.data;
    } else {
      console.log("Invalid API response:", response);
      return null;
    }

   
  } catch (err) {
    console.log("err", err);
  }
};

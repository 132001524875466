import React, { useEffect, useState } from "react";

import { FaMapMarkerAlt } from "react-icons/fa";
import { BiSolidPhoneCall } from "react-icons/bi";
import EmailIcon from "@mui/icons-material/Email";
import { BiSolidPaperPlane } from "react-icons/bi";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";

import { BsPinterest } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { FaSpotify } from "react-icons/fa";
import { PiSoundcloudLogoFill } from "react-icons/pi";

import { Link } from "react-router-dom";

import { SettingApi } from "../../ketanapi/Api";
import "../../styles/footer.css";

const Footer = () => {
  const [updatedSetting, setUpdateSetting] = useState();
  const [updateAddress, setUpdateAddress] = useState();

  useEffect(() => {
    const fetchSettingApi = async () => {
      try {
        const updateSetting = await SettingApi();
        const updateIcons = updateSetting?.filter(
          (item) =>
            item.slug === "instagram" ||
            item.slug === "facebook" ||
            item.slug === "youtube" ||
            item.slug === "spotify" ||
            item.slug === "soundcloud"
        );
        setUpdateSetting(updateIcons);
        const updateAddresses = updateSetting?.filter(
          (item) =>
            item.slug === "site-email" ||
            item.slug === "address" ||
            item.slug === "phone"
        );
        setUpdateAddress(updateAddresses);
      } catch (err) {
        console.log("error::", err);
      }
    };
    fetchSettingApi();
  }, []);

  return (
    <div>
      <div className="footer-container">
        <div className="contact-section">
          <p>Contact</p>
        </div>
        <div className="footer-icon">
          {updateAddress?.map((item, index) => (
            <React.Fragment key={index}>
              {item.slug === "site-email" && (
                <div className="footer-icon-wrap" key={item.slug}>
                  <BiSolidPaperPlane style={{ fontSize: "30px" }} />
                  <div>
                    <p>{item.title}</p>
                  </div>
                  <span>{item.value}</span>
                </div>
              )}

              {item.slug === "phone" && (
                <div className="footer-icon-wrap" key={item.slug}>
                  <BiSolidPhoneCall style={{ fontSize: "30px" }} />
                  <div>
                    <p>{item.title}</p>
                  </div>
                  <span>{item.value}</span>
                </div>
              )}

              {item.slug === "address" && (
                <div className="footer-icon-wrap" key={item.slug}>
                  <FaMapMarkerAlt style={{ fontSize: "30px" }} />
                  <div>
                    <p>{item.title}</p>
                  </div>
                  <span>{item.value}</span>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
        <div className="footer-bottom">
          <Link to="mailto:info@ketanmusic.com">
            <button>
              <EmailIcon style={{ fontSize: "25px" }} /> Contact Me
            </button>
          </Link>
        </div>
      </div>

      <div className="footer-section">
        <div className="footer-image">
          <img src="../../image/ketan-music.png" alt="" />
        </div>

        <div className="footer-icon-image">
          {updatedSetting?.map((item, index) => (
            <React.Fragment key={index}>
              <Link to={item.value} target="_blank">
                {item.slug === "facebook" && (
                  <FacebookOutlinedIcon style={{ fontSize: "24px" }} />
                )}
                {item.slug === "instagram" && (
                  <BsInstagram style={{ fontSize: "19px" }} />
                )}
                {item.slug === "spotify" && (
                  <FaSpotify style={{ fontSize: "20px" }} />
                )}
                {item.slug === "youtube" && (
                  <YouTubeIcon style={{ fontSize: "27px" }} />
                )}
                {item.slug === "soundcloud" && (
                  <PiSoundcloudLogoFill style={{ fontSize: "25px" }} />
                )}
                {item.slug === "pinterest" && (
                  <BsPinterest style={{ fontSize: "20px" }} />
                )}
              </Link>
            </React.Fragment>
          ))}
        </div>

        <div className="footer-copyright">
          <span>© 2023 Ketan Chhetri / All rights reserved</span>
        </div>
      </div>

      {/* <div className="term-policy">
        <p>Privacy Policy</p>
        <p>Terms & Conditions</p>
        <p>Cookies</p>
      </div> */}
    </div>
  );
};

export default Footer;

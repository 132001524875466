import React, { useEffect, useState } from "react";
import YouTube from "react-youtube";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

import Slider from "react-slick";

import { FaSpotify } from "react-icons/fa";
import { PiSoundcloudLogoFill } from "react-icons/pi";
import { TbPlayerPauseFilled } from "react-icons/tb";
import { TbPlayerPlayFilled } from "react-icons/tb";

import { Container } from "@mui/material";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
import ArrowForwardSharpIcon from "@mui/icons-material/ArrowForwardSharp";

import { SettingApi, VideoApi } from "../../ketanapi/Api";
import "../../styles/videoslider.css";
import Loading from "../Loading";
//import YouTubeEmbed from "../../YouTubeEmbed";

// const popularSong = [
//   {
//     images: " ../../image/image1.png",
//     title: "Lai Lai -Prasad",
//   },
//   {
//     images: "../../image/image2.png",
//     title: "Jindagi Ko Ke Chha Ra Bhara",
//   },
//   {
//     images: "../../image/image4.png",
//     title: " Aaja Maan ft. Ketan Chhetri",
//   },
//   {
//     images: " ../../image/image1.png",
//     title: "Lai Lai -Prasad",
//   },
//   {
//     images: "../../image/image2.png",
//     title: "Jindagi Ko Ke Chha Ra Bhara",
//   },
//   {
//     images: "../../image/image4.png",
//     title: " Aaja Maan ft. Ketan Chhetri",
//   },
// ];

const VideoSlider = () => {
  const [video, setVideo] = useState([]);
  const [handleTrue, setHandleTrue] = useState(false);
  const [imageSlider, setImageSlider] = useState(null);
  const [updatedSetting, setUpdateSetting] = useState();
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [popularSlider, setPopularSlider] = useState(null);
  const [selectedPopularImage, setSelectedPopularImage] = useState(null);
  const [pauseVideo, setPauseVideo] = useState(false);
  const [isVideoPlaying, setVideoPlaying] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [youtubeEmbedRef, setYoutubeRef] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleVideoLinkClick = (videoUrl) => {
    try {
      const url = new URL(videoUrl);
      const videoId = url.searchParams.get("v");

      if (videoId) {
        setSelectedVideo(videoId);
        setCurrentVideo(videoId);
      } else {
        console.error("Invalid YouTube video URL:", videoUrl);
      }
    } catch (error) {
      console.error("Invalid URL format:", videoUrl);
    }
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },

      // {
      //   breakpoint: 765,
      //   settings: {
      //     slidesToShow: 2,
      //     slidesToScroll: 2,
      //     initialSlide: 2,
      //   },
      // },

      {
        breakpoint: 765,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const handleImage = (i) => {
  //   setSelectedPopularImage(popularSong[i]);
  //   setSelectedImage(null);
  //   setHandleTrue(true);
  // };

  const goToNextImageSlide = () => {
    imageSlider.slickNext();
  };

  const goPrevToNextImageSlide = () => {
    imageSlider.slickPrev();
  };

  const goToNextPopularImageSlide = () => {
    popularSlider.slickNext();
  };

  const goToPrevPopularSlide = () => {
    popularSlider.slickPrev();
  };

  useEffect(() => {
    const fetchSettingApi = async () => {
      try {
        const updateSetting = await SettingApi();

        const updateIcons = updateSetting?.filter(
          (item) =>
            item.slug === "youtube" ||
            item.slug === "spotify" ||
            item.slug === "soundcloud"
        );
        setUpdateSetting(updateIcons);
        setIsLoading(false);
      } catch (err) {
        console.log("error::", err);
        setIsLoading(false);
      }
    };
    fetchSettingApi();
  }, []);

  useEffect(() => {
    const fetchVideoApi = async () => {
      try {
        const updateVideo = await VideoApi();
        setVideo(updateVideo);
      } catch (err) {
        console.log("error:", err);
      }
    };
    fetchVideoApi();
  }, []);

  const handlePauseVideo = () => {
    if (youtubeEmbedRef) {
      if (pauseVideo) {
        youtubeEmbedRef.playVideo();
      } else {
        youtubeEmbedRef.pauseVideo();
      }
    }
    setPauseVideo((prevPauseVideo) => !prevPauseVideo);
  };

  const opts = {
    width: "100%",
    height: "600px",
  };

  return (
    <div>
      <div className="videoslider-container">
        <div className="image-videoslider">
          {selectedVideo ? (
            <YouTube
              videoId={selectedVideo}
              opts={opts}
              onReady={(e) => setYoutubeRef(e.target)}
            />
          ) : handleTrue ? (
            selectedPopularImage ? (
              <YouTube videoId={selectedPopularImage?.link} />
            ) : null
          ) : selectedImage ? (
            <img src={selectedImage?.link} alt={selectedImage?.description} />
          ) : (
            <YouTube
              videoId={selectedPopularImage?.link}
              opts={opts}
              onReady={(e) => setYoutubeRef(e.target)}
            />
          )}
        </div>

        <div className="offical-wrap">
          <div className="offical">
            <div className="text-offical">
              <h5>Yo Maya Le Official M/V</h5>
              <div>
                <p>Also listen on</p>
              </div>
            </div>

            <div className="offical-icon">
              {updatedSetting?.map((item, index) => (
                <React.Fragment key={index}>
                  <Link to={item.value} target="_blank">
                    {item.slug === "youtube" && <YouTubeIcon />}
                    {item.slug === "spotify" && <FaSpotify />}
                    {item.slug === "soundcloud" && <PiSoundcloudLogoFill />}
                  </Link>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Container>
        <div className="newrelease-container">
          <div className="newrelease-title">
            <h4>|| Latest Releases</h4>
            <div className="icon-arrow">
              <ArrowBackSharpIcon onClick={goPrevToNextImageSlide} />
              <ArrowForwardSharpIcon onClick={goToNextImageSlide} />
            </div>
          </div>

          <div className="newrelease-song">
            {isLoading ? (
              <Loading />
            ) : (
              <Slider {...settings} ref={(slider) => setImageSlider(slider)}>
                {video?.map((items, i) => (
                  <React.Fragment key={i}>
                    <div
                      className={`image-newrelease-container ${
                        currentVideo === items.link ? "video-playing" : ""
                      }`}
                      onClick={() =>
                        handleVideoLinkClick(
                          `https://www.youtube.com/watch?v=${items.link}`
                        )
                      }
                    >
                      <img
                        src={`https://img.youtube.com/vi/${items.link}/0.jpg`}
                        alt=""
                      />

                      <span>
                        {parse(
                          items.description.length > 15
                            ? `${items.description.slice(0, 15)}...`
                            : items.description
                        )}
                      </span>

                      {currentVideo === items.link ? (
                        pauseVideo ? (
                          <TbPlayerPlayFilled
                            onClick={() => handlePauseVideo(items.link)}
                            className="icon-play-video"
                          />
                        ) : (
                          <TbPlayerPauseFilled
                            className="icon-play-video"
                            onClick={() => handlePauseVideo(items.link)}
                          />
                        )
                      ) : null}
                    </div>
                  </React.Fragment>
                ))}
              </Slider>
            )}
          </div>
        </div>

        {/* <div className="popular-container">
          <div className="popular-title">
            <h4>|| Popular Releases</h4>
            <div className="arrow-icon">
              <ArrowBackSharpIcon onClick={goToPrevPopularSlide} />
              <ArrowForwardSharpIcon onClick={goToNextPopularImageSlide} />
            </div>
          </div>
          <div className="popular-song-title ">
            <Slider {...settings} ref={(slider) => setPopularSlider(slider)}>
              {popularSong.map((items, i) => (
                <React.Fragment key={i}>
                  <div className="image-newrelease-container" key={i}>
                    <img
                      className="image-newRelease"
                      src={items.images}
                      alt={items.description}
                      onClick={() => handleImage(i)}
                    />
                    <p>{items.title}</p>
                  </div>
                </React.Fragment>
              ))}
            </Slider>
          </div>
        </div> */}
      </Container>
    </div>
  );
};

export default VideoSlider;
